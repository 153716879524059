body{
    overflow-x: hidden;
}

.stick{
    display: inline-block;
    position: sticky;
    bottom: 104px ;
    left: 1242px;
}
.stick i{
    padding: 20px 20px;
    background-color: yellow;
    border-radius: 50%;
   
}
.footer{
    background-image: url('../assets/footer.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
    margin-top: 150px;
    color: white;
}
a{
    text-decoration: none;
}
.foot{
    position: relative;
    top: -83px;
}

.foot .foot-top{
    position: relative;
    background-color: #ea0f8f ;
}

.top-info{
    display: flex;
    justify-content: space-around;
    color: black;
    padding: 40px 20px;
    align-items: center;
    
}
.foot-address .foot-icon li{
    position: relative;
}
.address i{
    position: absolute;
    left: 0;
    top: 0;
    font-size: 27px;
   
}
.address1 i{
    position: absolute;
    top: 0;
    font-size: 27px;
    left: 0;
}
.address2 i{
    position: absolute;
    top: 0;
    font-size: 27px;
    left: 0;
}
.last{
    flex-wrap: nowrap !important;
}

.foot-about .white{
    color: white !important;
}

.foot-about{
    line-height: 39px;
}
.foot-link{
    line-height: 39px;
}

.foot-link a{
    color: white;
}
.foot-address{
    line-height: 39px;
}
.foot-title::after{
    content: '';
    position: absolute;
    height: 2px;
    width: 50px;
    background-color:  #ea0f8f;
}

ul{
    list-style: none;
}
.tic li{
    position: relative;
}
.tic li::before{
    content:"\2714\0020";
    position: absolute;
    left: -25px;
    color:  #ea0f8f;
    font-size: 21px;

}
.foot-icon span.social_icons{
    color:  #ea0f8f;
}
.foot-icon i{
color:  #ea0f8f;
position: absolute;
left: 0;
top: 15px;
}

.footer-widget .white{
    color: white !important;
}
.footer-widget{
    margin-top: -9px !important;
    background-color: black;
height: 92px;
margin-top: 0px;
display: flex;
align-items: center;
}
.spa{
    color:  #ea0f8f;
}
.list-last{
    display: flex !important;
    gap: 20px !important;
}
@media screen and (max-width:992px){
    .top-info{
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        align-items: start;
    }
    .address i , .address1 i , .address2 i{
        top: 50%;
        transform: translateY(-50%);
    }
}
@media screen and (max-width:768px){
   
    .address{
        display: flex;
        gap: 70px;
    }
    .address1{
        display: flex;
        gap: 70px;
    }
    .address2{
        display: flex;
        gap: 70px;
    }

    /* .foot .foot-top{
        left: 32px;
        width: 385px;
    } */

    /* .feature-box .fa-rocket{
        top: 421px !important;
    } */
    /* .feature-box .fa-money-bill-1{
        top: 542px !important;
    } */
}
