

.payment{
    background-image: url('../assets/price.png');
    height: 280px;
    background-size: cover;
    position: relative;
    
}

.payment::before{
    background-color: rgba(0, 0, 0, 0.70);
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;

}

.payment-inner{
    content: '';
    color: rgb(255, 255, 255);
    position: absolute;
    width: 100%;
}
.payment-title{
    text-align: center;
}
.payment-title h3{
    font-size: 44px;
    font-weight: 600;
    line-height: 46px;
    text-align: center;
}
.payment-title::after{
    content: '';
    position: absolute;
    height: 2px;
    width: 50px;
    background-color: #ec3237;
    left: 48%;
}

.paymentlist ul{
    display: flex;
    gap: 10px;
    justify-content: center;
}
td:nth-child(1){
    text-align: right;
}
td:nth-child(2){
    font-weight: bold;
}
.pay-image {
   
    display: flex;
    justify-content: center;
}

@media screen and (max-width:768px){
    .paymentlist ul{
        gap: 5px !important;
    }
}
