.about{
    position: relative;
 }
 
 .about h1::after{
     content: '';
     position: absolute;
     height: 2px;
     width: 60px;
     background-color: crimson;
     top: 46px;
     left: 43px;
 }
 
 ul li{
     line-height: 30px;
 }
 
 ul.list-edit li:after {
     content: "\f040";
     font-family: 'FontAwesome';
     position: absolute;
     color: #ec3237;
     left: 0;
     top: 0;
 }
 
 ul.list-edit li {
 
     padding-left: 25px;
     position: relative;
 }
 @media screen and (max-width:768px){
     .look img{
         max-width: 100%;
     }
     /* .app{
         overflow-x: hidden !important;
     } */
 }