.float{
    position: fixed;
    bottom: 55px;
    right: 15px;
    z-index: 1;
}
.float i{
    padding: 15px 15px;
    color: white;
    background-color: green;
    border-radius: 50%;
    font-size: 35px;
}

.phone{
    position: fixed;
    bottom: 55px;
    left: 15px;
    z-index: 1;
}
.phone i{
    padding: 15px 15px;
    color: white;
    background-color: green;
    border-radius: 50%;
    font-size: 31px;
}

@media screen and (max-width:992px){
    
        .float{
            margin-left: 312px;
        }
    
}