ul li{
    line-height: 30px;
}

ul.list-edit li:after {
    content: "\f040";
    font-family: 'FontAwesome';
    position: absolute;
    color: #ec3237;
    left: 0;
    top: 0;
}

ul.list-edit li {

    padding-left: 25px;
    position: relative;
}