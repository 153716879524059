/* .price{
    background-image: url('../assets/price.png');
    height: 280px;
    position: relative;
  
}
.price::before{
    background-color: #000000ad;
    content: '';
    display: block;
    position: absolute;
} */

.price{
    background-image: url('../assets/price.png');
    height: 280px;
    background-size: cover;
    position: relative;
    
}
.price::before{
    background-color: rgba(0, 0, 0, 0.70);
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;

}

.price-inner{
    content: '';
    color: rgb(255, 255, 255);
    position: absolute;
    width: 100%;
}

.price-title h3{
    font-size: 44px;
    font-weight: 600;
    line-height: 46px;
    text-align: center;
}
.price-title::after{
    content: '';
    position: absolute;
    height: 2px;
    width: 50px;
    background-color: #ec3237;
    left: 48%;
}

.pricelist ul{
    display: flex;
    gap: 15px;
    justify-content: center;
}