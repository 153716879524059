.contact {
    background-image: url('../assets/price.png');
    height: 280px;
    background-size: cover;
    position: relative;

}

.contact::before {
    background-color: rgba(0, 0, 0, 0.70);
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;

}

.contact-inner {
    content: '';
    color: rgb(255, 255, 255);
    position: absolute;
    width: 100%;
}

.contact-inner:hover::after{
    width: 100px;
}


.contact-title h3 {
    font-size: 44px;
    font-weight: 600;
    line-height: 46px;
    text-align: center;
}

.contact-title::after {
    content: '';
    position: absolute;
    height: 2px;
    width: 50px;
    background-color: #ec3237;
    left: 48%;
}

.contactlist ul {
    display: flex;
    gap: 15px;
    justify-content: center;
}

.contact-info {
    background-color: #ea0f8f;
    color: white;
    height: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
}
.map_frame{
    height: 100%;
}

.add i {
    position: absolute;
    left: 13px;
    top: 96px;
    font-size: 27px;
}

.add1 i {
    position: absolute;
    left: 381px;
    top: 96px;
    font-size: 27px;
}

.add2 i {
    position: absolute;
    right: 400px;
    top: 96px;
    font-size: 27px;
}

.touch {
    position: relative;
}

.touch h3::before {
    content: '';
    position: absolute;
    height: 2px;
    width: 75px;
    background-color: #ec3237;
    top: 40px;
    left: 45%;
}
.form{
    margin-top: 50px;
}
.title-map h4{
    color: black;
}
.title-map{
    position: relative;
}
.title-map::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 50px;
    height: 2px;
    background: #ec3237;
    top: 32px;
}

.map-left{
    margin-top: 100px;
    margin-left: 50px;
}
.input-group{
    height: 60px;
}

.map{
    background-color: #f8f8f8;
}
textarea{
    height: 120px;
}
.but{
    margin-top: 80px;
    padding: 14px 31px;
   background-color: #0000ff ;
   color: white;
   border-radius: 5px;
   border-color: currentColor;
}
@media screen and (max-width:992px){

    .map_frame{
        height: 500px;
    }
}