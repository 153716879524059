

.top-navbar{
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 52px;
    background-color: #353535;
    color: white;
}

.first-top-nav{
    gap: 15px;
}

.top-navbar span i{
    color:  #ea0f8f;
}
.second-top-nav{
    display: flex;
    gap: 10px;
}
.drop ul{
    display: flex;
    flex-direction: column;
    background-color: black;
    color: white;
    position: absolute;
    z-index: 1;
    margin-top: 1px;
    visibility: hidden;
}

.drop ul li{
    border-bottom: 1px solid rgba(255,249,255,0.15) !important;
    height: 50px;
    width: 160px;
}
.drop ul a{
    color: white !important;
}
.hover{
    cursor: pointer;
}
.hover:hover .drop ul{
    visibility: visible;
    transition: all 0.25s ease;
}
.top-navbar span i{
    font-size: 14px;
}

.bottom-navbar{
    display: flex;
    justify-content: space-evenly;
    height: 115px;
    align-items: center;
    position: relative;
}
/* .bottom-navbar{
    position: sticky;
    top: 0px;
} */
.menu-links {
    list-style: none;
    display: flex;
    gap: 30px;
    font-weight: 600;
}
.menu-links li a{
    text-decoration: none;
    color: black;
}

.mobile i{
    display: none;
}

.marque{
    background:  #ea0f8f;
    height: 40px;
color: black;
font-weight: bold;
}
.img-responsive{
    display: block;
max-width: 100%;
height: auto;
}


.title {
        font-size: 34px;
        font-style: normal;
        font-weight: 600;
        line-height: 60px;
        position: relative;
    }
    .title::before{
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 50px;
        height: 2px;
        background:  #ea0f8f;
    }
.pink-bg{
    background-color: #ea0f8f ;
    height: 95px;
    display: flex;
    align-items: center;
}

.pink-bg h4{
    font-weight: 500;
    margin-top: 22px;
    color: white;
}
.pink-bg a{
    color: black;
    font-weight: 600;
    border: 2px solid white;
    background-color: #ea0f8f;
    padding: 14px 36px;
    transition: 0.2s ease;
    display: inline-block;
}
.purchase_row{
    row-gap: 15px;
}
.secondary_color{
    color:  #ea0f8f;
}
.primary_color{
    color: #ea0f8f;
}
.pink-bg a:hover{
    background-color: black;
    color: white;
}
.background{
    background-image: url('../assets/background.png');
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 400px;
    background-size: cover;
    position: relative;
    
}

.background::before{
    background-color: rgba(0, 0, 0, 0.68);
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
  }
.home p{
    color: black ;
}
 .section-title{
    color:  rgb(255, 255, 255);
    content: '';
    position: relative;
    margin-top: 50px;
 }

 .section-title h2{
    font-size: 39px;
    font-weight: bold;
 }
 .feature-box h3{
    color:  rgb(255, 255, 255) ;
    content: '';
    position: absolute;
 }
 .feature-box  .para{
    color:  rgb(255, 255, 255) !important;
    content: '';
    position: absolute;
 }

 .feature-box i{
    content: '';
    position: absolute;
    color:  #ea0f8f;
    font-size: 35px;
    left: 0;
    top: 0;
 }
 .feature-box{
    margin-left: 55px;
    /* margin-top: 100px; */
 }

 .feature-box .fa-rocket{
    content: '';
    position: absolute;
    color:  #ea0f8f;
    font-size: 35px;
    left:0;
    top:0;
 }

 .feature-box .fa-money-bill-1{
    content: '';
    position: absolute;
    color:  #ea0f8f;
    font-size: 35px;
    left: 0;
    top: 0;
 }
 .section-title::before{
    content: "";
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 90px;
    height: 2px;
    background:  #ea0f8f;
}
 .pink{
    color:  #ea0f8f; 
 }
 .bot{
    margin-top: 35px;
 }
 .brand {
    position: relative;
 }
 .brand h2::after{
    content: '';
    position: absolute;
    height: 2px;
    width: 65px;
    background-color:  #ea0f8f;
    bottom: 0;
 }
 a{
    text-decoration:none !important;
 }
.common_bgred{
    background-color:  #ea0f8f;
    z-index: 1;
}
 .card{
    display: flex;
    gap: 10px !important;
    background-color:  #ea0f8f;
    height: 150px;
 }
.card input[type='number']{
    width: 27% !important;
}
.padd{
    padding-bottom: 15px !important;
}
 .enquiry{
    color:  #ea0f8f;
 }
 .home{
    color:  #ea0f8f;
 }
 .pdf_frame{
    height: 700px;
 }
 /* .caro .visually-hidden{
    padding: 5px 5px;
    background-color: black;
    color: white;
 } */
 @media screen and (max-width:992px){
    .background{
        height: 650px;
    }
 }
 @media screen and (max-width:768px){
    /* body{
        width: 450px;
    } */
    .pdf_frame{
        height: 600px;
     }
    .top-navbar{
        display: none;
    }
    .bottom-navbar{
        position: relative;
        justify-content: space-around;
    }

  /* .title{
    color: black;
  } */
  /* .brand h2{
    color: black;
  } */
    .menu-links {
        display: none;
        flex-direction: column;
        gap: 20px;
        font-weight: 600;
        background-color: #151515;
        color: white;
        margin-top: -590px;
        height: auto;
        padding:20px 10px;
        width: 100%;
        position: absolute;
    }
    .menu-links.active {
        display:flex;
        margin-top: 60px;
        position: absolute;
        left: 0;
        z-index: 10;
        
    }
    .menu-links li a{
        color: white !important;
    }

    .home {
        color: white !important;
    }
    .mobile i{
        
        display: block;
        font-size: 23px;
        
    }
    .pink-bg{
        height: 177px;
    }
    .section-title h2 {
        font-size: 30px;
    }
    .feature-box i{
        font-size: 30px;
    }
    .background{
        height: 674px;
    }
    .footer{
        height: auto !important;
    }
    .last{
        display: block !important;
    }
    .card{
        flex-direction: column !important;
    }
   
 }
 @media screen and (max-width:500px){

    .pdf_frame{
        height: 500px;
     }
}
