body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* .table_wrap , .checkout_table_wrap{
  overflow-x: auto;
} */
.qtyInput{
  width: 100%;
}
.checkout_parent{
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 40px;
  height: 40px;
  bottom: 160px;
  right: 21px;
  background-color: #5a14f7;
  background: linear-gradient(90deg, #ffd805, #f70818);
  color: #fff;
  border-radius: 60px;
  text-align: center;
  font-size: 20px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}
.checkout_parent i{
  font-size: 16px;
}
a{
  text-decoration: none;
}
a:hover{
  color: white;
}
.checkout_parent .checkout_product{
  position: absolute;
  top: -15px;
  left: -5px;
  min-width: 26px;
  height: 26px;
  background-color: #5a14f7;
  border-radius: 77px!important;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.checkout_table_wrap .qtyInput{
  width: 200px;
}
.checkout_table_wrap table{
  min-width:550px;
}
.product-sticky{
  position: sticky;
  top: 0;
}
.pointer-events-none{
  pointer-events: none;
}
.checkout_form input ,.checkout_form textarea{
  background: #f8f8f8;
    height: auto;
    padding: 16px 15px;
    box-shadow: none;
    border: 1px solid gray;
}
.cursor-pointer{
  cursor: pointer;
}
@media(max-width: 990px) {
  .qtyInput , .checkout_table_wrap .qtyInput{
    width: 90px;
    font-size: 15px;
  }
  .quick_purchase table{
    font-size: 15px;
  }
}
@media(max-width: 740px) {
  .qtyInput , .checkout_table_wrap .qtyInput{
    width: 60px;
    font-size: 14px;
  }
  .quick_purchase table{
    font-size: 14px;
  }
  .quick_purchase table td{
    padding: 0.3rem;
  }
}
@media(max-width: 500px) {
  .qtyInput , .checkout_table_wrap .qtyInput{
    width: 45px;
    font-size: 10px;
    padding: 2px 3px;
  }
  .quick_purchase table{
    font-size: 10px;
  }
  .quick_purchase table td{
    padding: 0.2rem;
  }
}